<template>
  <div class="page-header">
    <h3>All Bookings</h3>
    <button @click="showBookingModal = true">New Booking</button>
  </div>
  <div class="container">
    <div class="inner-container">
      <div class="page-subtitle">List of Bookings 🚗</div>
      <BookingTable />
    </div>
  </div>
  <BookingModal v-if="showBookingModal" @add-client="showClientModal = true" @modal-close="showBookingModal = false" />
  <ClientModal v-if="showClientModal" @modal-close="showClientModal = false" />
</template>

<script>
import { ref } from 'vue'
import BookingTable from '@/components/Booking/Table.vue'
import BookingModal from '@/components/Booking/Modal.vue'
import ClientModal from '@/components/Client/Modal.vue'

export default {
  components: { BookingTable, BookingModal, ClientModal },
  setup() {
    const showBookingModal = ref(false)
    const showClientModal = ref(false)

    return { showBookingModal, showClientModal }
  }
}
</script>